.pac-container {
  z-index: 1051 !important;
}

.p-dialog-mask {
  background-color: #0006 !important;
}

.modal-backdrop {
    opacity: .5;
}

.modal-header,
.alert {
    .close {
        padding: 0.5rem 1.75rem;
        background: transparent;
        border: 0;
        font-size: 25px;
        position: absolute;
        top: 0;
        right: 0;
        opacity: .5;
        width: 1em;
        height: 1em;
        z-index: 2;

        button {
            display: none;
        }
    }
}

.form-row {
    display: flex;
    flex-wrap: wrap;
}

.vhd__datepicker__input {
    //height: $input-height !important;
    //line-height: $input-height !important;
}

.form-check {
    .custom-control-input {
        border-radius: .25em;
        float: left;
        margin: 3px 3px 3px -19px !important;
    }
}

.check-group {
    input {
        position: absolute;
        clip: rect(0,0,0,0);
        pointer-events: none;
    }
}

.custom-select {
    padding-top: .25rem;
    padding-bottom: .25rem;
    padding-left: .5rem;
    font-size: .7875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.owl-nav {
    margin-top: 16px;
    text-align: center;

    button {
        width: 30px;
        height: 30px;
        line-height: 28px !important;
        font-size: 20px !important;
        border-radius: 50% !important;
        //background-color: rgba($primary, 0.25) !important;
        //color: $primary !important;
        margin: 4px 8px !important;
        border: none;
    }
}
.form-row {
    display: flex;
    flex-wrap: wrap;
}

.vhd__datepicker__input {
    //height: $input-height !important;
    //line-height: $input-height !important;
}

.form-check {
    .custom-control-input {
        border-radius: .25em;
        float: left;
        margin: 3px 3px 3px -19px !important;
    }
}

.check-group {
    input {
        position: absolute;
        clip: rect(0, 0, 0, 0);
        pointer-events: none;
    }
}

.form-check {
    .custom-control-input {
        background-color: #556ee6;
        border-color: #556ee6;
    }
}
