@tailwind base;
@tailwind components;
@tailwind utilities;
@import "primeicons/primeicons.css";
@import "assets/scss/_fonts.scss";
@import "assets/scss/_custom.scss";
html {
  font-size: 14px;
}

html, body { height: 100%; }
body { margin: 0}

.pac-container {
  z-index: 100000 !important;
}
